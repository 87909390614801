@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.cur-item {
  width: 96px;
  height: 24px;
  border-radius: 4px;
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  cursor: pointer;
  margin-right: 35px;
}

.cur-item-active {
  color: #fff;
  background: #7f76bd;
}

.fission-avator {
  width: 30px;
  height: 30px;
  background: #efeef4;
  border-radius: 15px;
}
.up-name {
  width: 192px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.btn {
  background: #7f76bd;
  width: 62px;
  height: 24px;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  padding: 0 !important;
  font-size: 14px;
}
.bg-white {
  background: #fff;
  line-height: 28px;
}

.fission-label {
  padding: 3px 8px;
  background: #eceafa;
  border-radius: 4px;
  font-weight: 400;
  font-size: 15px;
  margin-right: 10px;
  width: 70px;
  text-align: center;
  height: 22px;
}
