@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.cur-item {
  width: 96px;
  height: 24px;
  border-radius: 4px;
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  cursor: pointer;
  margin-right: 35px;
}

.cur-item-active {
  color: #fff;
  background: #7f76bd;
}
.btn {
  background: #7f76bd;
  width: 62px;
  height: 24px;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  padding: 0 !important;
  font-size: 14px;
}
.bg-white {
  background: #fff;
  line-height: 28px;
}

.fission-label {
  padding: 3px 8px;
  background: #eceafa;
  border-radius: 4px;
  font-weight: 400;
  font-size: 15px;
  margin-right: 10px;
  width: 70px;
  text-align: center;
  height: 22px;
}
.table-header {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 50px;
  color: #999999;
  font-size: 14px;
  text-align: center;
}

.item {
  color: #333333;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.item-message {
  margin-left: 10px;
  width: 30%;
}
.shenglue-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item-index {
  margin-left: 10px;
  min-width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 2px solid #c5c0e0;
  color: #c5c0e0;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
}

.bg {
  background: #7f76bd;
  color: #fff;
}
.position-btn {
  border: 1px solid #c5c0e0;
  width: 80px;
  height: 20px;
  line-height: 20px;
  border-radius: 5px;
  font-size: 14px;
}

.page-box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -40px;
}
.page-text {
  color: #999999;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 10px;
}
